<template>
<div>
    <div id="left-menu-id" class="left-menu">
        <template v-if="!showmenu">
        <div class="logo-qet-up" id="logo-qet-up">
             <img src="../../assets/index/QET_logo.png"/>
             
             <!-- <i class="el-icon-menu" style="font-size:20px" @click="handleShowmenu"></i> -->
        </div>
        <img class="s_icon_1"  src="../../assets/groupIntro/s_icon.png" @click="handleShowmenu"/>
        </template>
        <div class="logo-qet" id="logo-qet"  v-show="showmenu">
             <img src="../../assets/index/QET_logo.png"/>
             
             <!-- <i class="el-icon-menu" style="font-size:20px" @click="handleShowmenu"></i> -->
        </div>
        <!-- <img class="s_icon_1" src="../../assets/groupIntro/s_icon.png" @click="handleShowmenu"/> -->
        <!-- <el-collapse-transition> -->
        <div v-show="showmenu">
        <ul class="menu-ul">
            <li :class="{'active': $route.path === item.path || currentChildRoute&&currentChildRoute.parent === item.path}" v-for="(item, index) in menus" :key="index"
             @click="handleRouter(item)" @mouseenter.self="handleMouseEnter(item)" >
                {{item.name}}
                <p style="margin: 4px 0 0 0;" v-if="$route.path === item.path || currentChildRoute&&currentChildRoute.parent === item.path">{{item.en}}</p>
                <template v-for="(child, i) in item.children">
                <!-- <div class="children-li" :class="{'active': $route.path===child.path}" :key="i" v-if="$route.path===item.path ||  (item.path === child.parent)"  -->
                <div class="children-li" :class="{'active': $route.path===child.path}" :key="i" v-if="item.childArr.includes($route.path)" 
                @click.stop="handleRouter(child)" @mouseenter.self="handleMouseEnter(child)" >
                   {{child.name}}
                </div>
                </template>
                
            </li>
            <template>
                <div v-if="selectNodes.length>0">
                    <ul class="node-ul" @mouseleave="handleMouseLeave()">
                        <li v-for="(node, n) in selectNodes" :key="n" @click="handleRouter(node)">{{node.name}}</li>
                    </ul>
                </div>
            </template>
            <li>
           
            
            <div class="footer-box">
                <div class="brand-phone">
                    <p>品牌合作热线：</p>
                    <h5>{{ $settingList.find(setting => setting.keyword === 'service_tel')?.value }}</h5>
                </div>
                <!-- <div class="lang-box">language : <img alt="" class="flag" src="../../assets/index/china_flag.png"></div> -->
                <div class="icp-box">
                    <a href="https://beian.miit.gov.cn" target="_blank" style="color:#FFF;text-decoration: none;"> {{ $settingList.find(setting => setting.keyword === 'beianhao')?.value }}</a>
                </div>
                <img class="s_icon_2" v-if="showmenu" src="../../assets/groupIntro/s_icon.png" @click="handleShowmenu"/>
            </div>
            </li>
            <!-- <div class="login-box">
                <span class="login" @click="$router.push('Login')">登录</span>
                <span> / </span>
                <span class="register" @click="$router.push('Register')"> 注册</span>
            </div> -->
        </ul>
        
        </div>
        <!-- </el-collapse-transition> -->
    </div>
    <!-- 页面内容 -->
    <router-view/>
</div>
</template>

<script>
export default {
    data() {
        return {
            showmenu: true,
            menus:[
                {
                    path: '/index',
                    name: '首页',
                    en: 'HOME',
                    level: 1,
                    childArr: [],
                    children: []
                },
                {
                    path: "/groupIntro",
                    name: '集团概述',
                    en: 'Group Overview',
                    level: 1,
                    childArr: [],
                    children: [],
                    nodes: [
                        {
                            path: "/groupIntro",
                            name: '集团简介'
                        },
                        {
                            path: "/groupHistroy",
                            name: '集团历程'
                        },
                    ]
                },
                {
                    path: '/paulFrank',
                    name: '集团品牌',
                    level: 1,
                    en: '',
                    childArr: ['/paulFrank','/bOBDOG','/boboDog'],
                    children: [
                        {
                            path: '/paulFrank',
                            name: 'PAUL FRANK',
                            parent: '/paulFrank',
                            nodes: [
                                {
                                    path: "#1",
                                    name: '品牌故事'
                                },
                                {
                                    path: "#2",
                                    name: 'IP形象'
                                },
                                {
                                    path: "#3",
                                    name: '当季新款'
                                },
                                {
                                    path: "#4",
                                    name: '形象店展示'
                                },
                            ]
                        },
                        {
                            path: '/bOBDOG',
                            name: 'BOBDOG',
                            parent: '/paulFrank',
                            nodes: [
                                {
                                    path: "#1",
                                    name: '品牌故事'
                                },
                                {
                                    path: "#2",
                                    name: '品牌历程'
                                },
                                {
                                    path: "#3",
                                    name: 'IP形象'
                                },
                                {
                                    path: "#4",
                                    name: '当季新款'
                                },
                                {
                                    path: "#5",
                                    name: '形象店展示'
                                },
                            ]
                        },
                        {
                            path: '/boboDog',
                            name: 'POPPLE  TEDDY',
                            parent: '/paulFrank',
                            nodes: [
                                {
                                    path: "#1",
                                    name: '品牌故事'
                                },
                                {
                                    path: "#2",
                                    name: 'IP形象'
                                },
                                {
                                    path: "#3",
                                    name: '品牌历程'
                                },
                                {
                                    path: "#4",
                                    name: '形象店展示'
                                },
                            ]
                        },
                    ]
                },
                {
                    path: '/innovation',
                    name: '科研创新',
                    en: 'Innovation',
                    level: 1,
                    childArr: [],
                    nodes: [
                        // {
                        //     path: "#1",
                        //     name: "科研团队"
                        // },
                        // {
                        //     path: "#2",
                        //     name: "专利证书"
                        // },
                        // {
                        //     path: "#3",
                        //     name: "科研产品"
                        // },
                    ],
                    children: []
                },
                // {
                //     path: '/joinBrand',
                //     name: '品牌加盟',
                //     en: 'Band Joined',
                //     level: 1,
                //     childArr: [],
                //     nodes: [],
                //     children: []
                // },
                {
                    path: "/companyNews",
                    name: '最新动态',
                    en: 'Latest News',
                    level: 1,
                    childArr: [],
                    nodes: [
                        {
                            path: "/companyNews",
                            name: "企业新闻"
                        },
                        {
                            path: "/socialNews",
                            name: "社会公益"
                        },
                    ],
                    children: []
                },
                {
                    path: "/contactUs",
                    name: '加入我们',
                    en: 'Join us',
                    level: 1,
                    childArr: [],
                    nodes: [
                         {
                            path: "/contactUs",
                            name: "联系方式"
                        },
                        {
                            path: "/joinUs",
                            name: "招聘信息"
                        },
                    ],
                    children: []
                },
            ],
            currentChildRoute: null,
            selectNodes: [],
        }
    },
    created() {
        // console.log(this.$route);
        let childs = this.menus.filter((item)=>{ 
            if (item.childArr) {
               return item.childArr.includes(this.$route.path)
            }
            
        });
        // console.log(childs);
        // console.log(childs[0].children);
        if (childs[0] && childs[0].children && childs[0].children.length>0) {
            // console.log('=========');
           this.currentChildRoute = childs[0].children.filter(item=>item.path===this.$route.path)[0];
            // console.log(this.currentChildRoute);
        }
        
    },
    computed:{
        isActive() {
            // 顶级导航,当前路由为顶级导航
            // let tops = this.menus.forEach(item => {
                
            // });((item)=> item.path === this.$route.path);
            // console.log(tops);
            // if (tops && tops.length>0) {
            //     return tops[0].path === ;
            // }
            // 子导航
            // let childs = this.menus.filter((item)=> item.path === this.$route.path);
            // if (childs && childs.length>0 && childs.childArr.includes()) {
            //     return true;
            // }
            // let childs = this.menus.filter((item)=> item.path === this.currentMenuRoute.parent);
            // if (childs && childs.length>0) {
            //     return true;
            // }
            return false;
        },
        // isCurrenChild() {
        //     // this.$router.path = 
        // }
    },
    methods:{
        handleRouter(item) {
            if (!item.path) {
                return;
            }
            // console.log(item);
            this.currentChildRoute = item;
            this.$router.push(item.path)
        },
        handleMouseEnter(item) {
            // console.log(item.name);
            this.selectNodes = item.nodes || [];
            let dom1 = document.getElementById('child-name');
            if(dom1 && dom1.classList){
                dom1.classList.add('animated');
                dom1.classList.add('fadeInUpSmall');
            }
         
            // console.log('子菜单开始');
        },
        handleMouseLeave() {
            this.selectNodes = [];
        },
        handleShowmenu() {
            this.showmenu = !this.showmenu;
            // console.log(this.showmenu);
            this.$nextTick(()=>{
                if (this.showmenu) {
                    // document.getElementById('left-menu-id').style.height = '92vh';
                    let dom = document.getElementById('left-menu-id');
                    dom.classList.add('left-menu-h92');
                    dom.classList.remove('left-menu-h15')
                } else{
                    // document.getElementById('left-menu-id').style.height = '15vh';
                    let dom = document.getElementById('left-menu-id');
                    dom.classList.add('left-menu-h15');
                    dom.classList.remove('left-menu-h92')
                    document.getElementById('logo-qet').style.margin = '60% 60px 20% 60px';
                }
            })
            
        },
        // 关闭menu
        closeMenu() {
            this.$nextTick(()=>{
                // document.getElementById('left-menu-id').style.height = '15vh';
                let dom = document.getElementById('left-menu-id');
                dom.classList.add('left-menu-h15');
                dom.classList.remove('left-menu-h92')
                document.getElementById('logo-qet').style.margin = '60% 60px 20% 60px';
            })
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            // 通过 `vm` 访问组件实例
            if (to.name === 'groupIntro' || to.name === 'product' || to.name === 'productDetail') {
            // if (to.name === 'groupIntro') {
                vm.showmenu = false;
                vm.closeMenu();
            }
        })
    },
    beforeRouteUpdate(to, from, next) {
        if (to.name === 'groupIntro' || to.name === 'product' || to.name === 'productDetail') {
            this.showmenu = false;
            this.closeMenu();
        }
        next()
    }

}
</script>

<style scoped>
.brand-phone{
       color: #ffffff;
    margin-bottom: 10px;
    position: absolute;
    bottom: 3vh;
    left: -30px;
    width: 200px;
}
.brand-phone p{
       font-size: 18px;
    padding: 0;
    margin: 0;
}
.brand-phone h5{
      font-size: 27px;
    padding: 0;
    margin: 5px 0;
    font-weight: normal;
    /* letter-spacing: 2px; */

}
@keyframes mymove{
	0%   {top:0px;}
	25%  {top:30vh;}
	75%  {top:60vh}
	100% {top:92vh;}
}
.left-menu-h92{
    height: 92vh !important;
    /* animation:mymove 3s;
	-webkit-animation:mymove 3s; */
}
.left-menu-h15{
    height: 13vh !important;
}
.left-menu{
    width: 240px;
    max-width: 240px;
    background: #EE660E;
    position: fixed;
    font-size: 17px;
font-family: AlibabaPuHuiTiM;
font-weight: 400;
color: #FFA56A;
/* padding: 54px 0; */
height: 92vh;
box-shadow: 0px 0px 10px 1px #767676;
z-index: 999;
top: 4vh;
    margin-left: 24px;
        transition: height 0.4s ease;
}
.menu-ul {
        list-style: none;
        text-align: left;
        padding: 0;
        margin: 0;
        padding-left: 60px;
}
.menu-ul li{
    cursor: pointer;
    /* margin-bottom: 36px; */
    margin-bottom: 10.6%;
}
.menu-ul li.active{
    font-size: 17px;
font-family: AlibabaPuHuiTiM;
font-weight: bolder;
color: #FFFFFF;
}
.menu-ul li:hover{
    font-size: 17px;
font-family: AlibabaPuHuiTiM;
font-weight: 400;
color: #FFFFFF;
}
.children-li{
    font-size: 17px;
font-family: AlibabaPuHuiTiM;
font-weight: 400;
color: #FF8D43;
background: #EE660E;
margin-top: 16px;
}
.children-li:hover{
    text-decoration: underline 3px;
    color: #ffffff;
    text-decoration-color: #FF8D43;
    -moz-text-decoration-color: #FF8D43; /* 针对 Firefox 的代码 */
}
.children-li.active{
    text-decoration: underline 3px;
    color: #ffffff;
    text-decoration-color: #FF8D43;
    -moz-text-decoration-color: #FF8D43; /* 针对 Firefox 的代码 */
}
.node-ul{
    position: absolute;
    background: #ffffff;
    left: 240px;
    top: 0;
    padding: 0;
    margin: 0;
    width: 200px;
    list-style: none;
    height: 100%;
    font-size: 17px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #FFA56A;
box-shadow: 6px 3px 12px 1px #b1b1b1;
text-align: center;
}
.node-ul li{
    
}
.node-ul li:hover{
    font-size: 17px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #000000;
text-decoration: underline 3px;
    text-decoration-color: #FFA56A;
    -moz-text-decoration-color: #FFA56A; /* 针对 Firefox 的代码 */
}
.node-ul li:first-child{
    margin-top: 36vh;
}
.logo-qet{
border-bottom: 2px solid #FFA56A;
    margin: 60px 60px 20px 60px;
    font-size: 42px;
    color: #ffffff;
    font-weight: 800;
        padding-bottom: 12px;
            padding-bottom: 1%;
            margin: 88% 60px 10% 60px;
            text-align: center;
}
.logo-qet-up{
    border-bottom: 2px solid #FFA56A;
    font-size: 42px;
    color: #ffffff;
    padding-bottom: 1%;
    margin: 7% 60px 20% 60px;
    text-align: center;
}
.footer-box{
    /* padding-left: 60px; */
    font-size: 13px;
    font-weight: 400;
        position: absolute;
    bottom: 3vh;
}
.lang-box{
    color: #ffffff;
    padding-bottom: 1vh;
}
.icp-box{

}
.flag{
    vertical-align: middle;
}
/* 超过1920 */
@media screen and (min-width:1800px) {
    .logo-qet {
        margin: 60% 60px 20% 60px;
    }
    .menu-ul li{
    margin-bottom: 20%;
    }
}
/* 小于1500 */
@media screen and (max-width:1500px) {
    .logo-qet {
        margin: 60% 60px 10% 60px;
    }
    .menu-ul li{
    margin-bottom: 10%;
    }
    .left-menu {
        font-size: 16px;
    }
    .children-li[data-v-62f08519] {
    font-size: 14px;
    margin-top: 14px;
}
.menu-ul li.active {
    font-size: 16px;
}
.node-ul {
    font-size: 14px;
}
}
.s_icon_1{
    position: relative;
    top: -42px;
    left: 101px;
    cursor: pointer;
}
.s_icon_2{
position: relative;
    top: 12px;
    left: 41px;
}
.login-box{
    position: absolute;
    bottom: 19vh;
    color: #fff;
    cursor: pointer;
}
.login-box .login:hover{
    color: #FFA56A;
}
.login-box .register:hover{
    color: #FFA56A;
}
</style>